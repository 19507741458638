

import { defineComponent, computed } from 'vue';

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'info',
      validator: (value: string) => ['info', 'success', 'warning', 'error'].includes(value),
    },
  },
  setup(props) {
    type Color = {
      type: string,
      icon: string,
      text: string,
    };

    const colors = [
      {
        type: 'info',
        icon: 'bg-blue-400',
        text: 'text-blue-500 dark:text-blue-400',
      },
      {
        type: 'success',
        icon: 'bg-green-400',
        text: 'text-green-500 dark:text-green-400',
      },
      {
        type: 'warning',
        icon: 'bg-yellow-400',
        text: 'text-yellow-500 dark:text-yellow-400',
      },
      {
        type: 'error',
        icon: 'bg-red-400',
        text: 'text-red-500 dark:text-red-400',
        shadow: 'rgba(251, 191, 36, var(--tw-bg-opacity))',
      },
    ];
    const colorDisplayed = computed(
      (): Color => colors.find((color: Color) => color.type === props.type) || colors[0],
    );

    return {
      colorDisplayed,
    };
  },
});
