
import {
  defineComponent,
  ref,
  Ref,
  computed,
  ComputedRef,
} from 'vue';
import { useRouter } from 'vue-router';

import { useStore } from '@/store/index';

import Alert from '@/components/utils/Alert.vue';

import { AuthActionTypes, UserActionTypes } from '@/store/types/action.type';

export default defineComponent({
  name: 'login',
  components: {
    Alert,
  },
  setup() {
    const store = useStore();

    const router = useRouter();

    const email: Ref<string> = ref('');
    const password: Ref<string> = ref('');
    const isWrongLogsDisplayed: Ref<boolean> = ref(false);

    const isLoginLoading: ComputedRef<boolean> = computed(() => store.getters['authStore/isLoginLoading']);

    const loginClick = async () => {
      try {
        await store.dispatch(AuthActionTypes.login, {
          email: email.value, password: password.value,
        });
        await store.dispatch(UserActionTypes.getUser);
        isWrongLogsDisplayed.value = false;
        router.push({ name: 'home' });
      } catch (error) {
        isWrongLogsDisplayed.value = true;
      }
    };

    return {
      email,
      password,
      isWrongLogsDisplayed,
      isLoginLoading,
      loginClick,
    };
  },
});
